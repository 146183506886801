<template>
  <div class="payment-help" v-show="text">
    <div class="payment-help__icon">
      <HelpIcon/>
    </div>

    <div class="payment-help__text-wrap">
      <div class="payment-help__triangle"></div>
      <div class="payment-help__text text text__twelve">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import HelpIcon from "../../icons/HelpIcon.vue";

export default {
  name: "PaymentHelp",
  components: {
    HelpIcon
  },
  props: {
    text: {
      type: String,
    }
  }
}
</script>