<template>
  <div class="form-field" :class="blockClasses">
    <label v-if="field.label" :class="elementClasses('label')">
      {{ field.label }}
    </label>

    <div class="custom-select custom-select_multi"
         :class="{_open: isOpened}"
         ref="customSelect"
    >
      <div class="custom-select__inner">
        <div class="custom-select__field-wrapper">
          <input type="text"
                 class="custom-select__field"
                 :value="searchInputValue"
                 @click="open"
                 :readonly="!isOpened"
                 v-show="!hasCheckedValues || isOpened"
                 @input="search"
                 ref="searchInput"
          >
          <input type="text"
                 class="custom-select__field"
                 :value="valueItem.value"
                 @click="open"
                 readonly
                 v-show="hasCheckedValues && !isOpened"
          >
          <div class="custom-select__arrow-icon custom-select__arrow-icon_absolute">
            <ArrowDown/>
          </div>
        </div>

        <div class="custom-select__content-wrap">
          <ul class="custom-select__list">
            <li v-show="!field.choices.length || (this.searchValue && !choices.length)">
              <div class="custom-select__no-choices">
                Нет результатов
              </div>
            </li>
            <li v-for="(choice, index) in choices" class="custom-select__item">
              <input
                  type="checkbox"
                  :id="id(choice, index)"
                  :value="choice.key"
                  class="custom-select__input"
                  v-model="valueItem.value"
              >
              <label :for="id(choice, index)" class="custom-select__label">
                <span class="custom-select__choice-icon">
                  <CheckMarkBlack/>
                </span>
                <span class="custom-select__choice-name">
                  {{ choice.label }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {fieldMixin} from "./fieldMixin";
  import ArrowDown from "../../components/icons/ArrowDown.vue";
  import CheckMarkBlack from "../../components/icons/CheckMarkBlack.vue";
  import _ from "lodash";

  export default {
    name: "SelectMultiField",
    components: {
      CheckMarkBlack,
      ArrowDown
    },
    mixins: [
      fieldMixin
    ],
    data() {
      return {
        isOpen: false,
        searchValue: '',
      }
    },
    computed: {
      hasCheckedValues() {
        return this.valueItem.value ? this.valueItem.value.length : false;
      },
      choices() {
        let choices = this.field.choices;
        const orderedChoices = this.field.data.orderedChoices;
        if (orderedChoices && orderedChoices.length) {
          choices.sort((a, b) => {
            if (!orderedChoices.includes(a.key) || !orderedChoices.includes(b.key)) {
              return;
            }
            return orderedChoices.indexOf(a.key) - orderedChoices.indexOf(b.key);
          });
        }
        if (this.searchValue) {
          choices = choices.filter((choice) => {
            const choiceKey = choice.key.toLowerCase();
            if (choiceKey.includes(this.searchValue.toLowerCase().trim())) {
              return choice;
            }
          });
        }

        return choices;
      },
      isOpened() {
        return this.isOpen;
      },
      searchInputValue() {
        return !this.isOpen ? 'Все' : this.searchValue;
      }
    },
    watch: {
      isOpened: {
        handler(value) {
          if (value) {
            setTimeout(() => {
              this.$refs.searchInput.focus();
            }, 100);
          } else {
            this.searchValue = '';
          }
        }
      }
    },
    methods: {
      handleInput(e) {
        setTimeout(() => {
          const inputValue = e.target.value
          const matchedChoice = this.choices.find((choice) => choice.key === inputValue);
          if (matchedChoice) {
            this.valueItem.value = [matchedChoice.key];
          }
        }, 500)
      },
      checkValue() {
        if (!this.valueItem.value) {
          this.valueItem.value = [];
        }
      },
      id(choice, index) {
        index = index | 0;
        return `${this.field.key}_${choice.key}_${index}`;
      },
      open() {
        this.isOpen = true;
      },
      search(e) {
        this.searchValue = e.target.value;
      }
    },
    mounted() {
      this.checkValue();

      document.addEventListener('click', (e) => {
        const el = this.$refs.customSelect;
        if (this.isOpen && !(el === e.target || el.contains(e.target))) {
          this.isOpen = false;
        }
      });

      document.addEventListener('keydown', this.handleInput);
    },
    beforeUpdate() {
      this.checkValue();
    },
  }
</script>

<style lang="scss">

</style>